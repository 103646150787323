import React, { Component, useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { User } from './providers/User.js';

import LandingPage from './components/LandingPage';
import Home from './pages/Home4';
import Contact from './components/Contact';
import Support from './components/Support';
import Wallet from './pages/Wallet3.js';
import BecomeMerchant from './components/BecomeMerchant';
import Stacking from './components/Stacking5';
import StackCoins from './components/StackCoins';
import WordBackup from './components/WordBackup';
import Privacy from './components/Privacy';
import BrandAssets from './components/BrandAssets';
import Terms from './components/Terms';
import Exchange from './pages/Exchange';
import Media from './components/Media';
import MeetUps from './components/MeetUps';
import Press from './components/Press';
import NotFound from './components/NotFound.js';
import WhitePaper from './components/WhitePaper';
import YellowPaper from './components/YellowPaper';
import About from './components/About';
import BecomeAnAffiliate from './components/BecomeAnAffiliate';
import MassAdoption from './components/MassAdoption';
import ReactGA from 'react-ga';
import {GA_ID} from './constants/defaultValues'
import YearEndReport from './components/YearEndReport'
import DearStakeholders from './components/DearStakeholders';
import ConsumerWalletApp from './components/ConsumerWalletApp'
import MerchantWalletApp from './components/MerchantWalletApp'
import Mindmap from './components/Mindmap2'
import NewHome from './components/NewHome'
import BugBounty from './components/BugBounty';
import Top100Holders from './components/Top100Holders';
import NFTCollections from './components/NFTCollections2';
import Faqs from './components/Faqs';
import Mining from './components/Mining';
import FoundersClubTop50NFT from './components/FoundersClubTop50NFT';
import FoundersClub from './components/FoundersClub';
import NFTs from './components/NFTs';
import NFTsSingle from './components/NFTsSingle2';
import LandingNew from './components/LandingNew';
import LettersToStakeholders from './components/LettersToStakeholders';
import CollectYourLoyalNFT from './components/CollectYourLoyalNFT';
import OvatoNation from './pages/newsletter/OvatoNation';
import OvatoNation2 from './pages/newsletter/OvatoNation2';
import OvatoNation3 from './pages/newsletter/OvatoNation3';
import PledgeDrive from './pages/pledge-drive/PledgeDrive';
import Announcement from './pages/Announcement';
import TestData from './components/TestData';
import HardFork from './pages/newsletter/HardFork';

import FinancialOverview from './pages/FinancialOverview2.js';

import Dao from './pages/Dao.js';
import Discover from './pages/Discover.js';

// Maintenance Page
import Maintenance from './pages/Maintenance.js';

// Premium Pages
import Profile from './pages/premium/Profile.js';
import Organization from './pages/premium/Organization.js';
import SendReceive from './pages/premium/SendReceive.js';
import Earnings from './pages/premium/Earnings.js';
import Tools from './pages/premium/Tools.js';

import Dispensary from './pages/Dispensary.js';
import Escorts from './pages/Escorts.js';
import Unbanked from './pages/Unbanked.js';

// Test Data
import TestData2 from './pages/TestData.js';
import PasswordReset from './components/PasswordReset.js';
import PaymentCard from './pages/PaymentCard.js';
import CheckoutStripe from './pages/CheckoutStripe.js';
import Leaderboard from './pages/Leaderboard.js';
import PrivateRoute from './components/authentication/PrivateRoute.js';
import Congratulations from './pages/Congratulations2.js';
import BusinessInABox from './pages/BusinessInABox.js';
import BusinessInABoxMerchantInvitation from './pages/BusinessInABoxMerchantInvitation.js';

// const PrivateRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) =>
//       User.hasToken() ? (
//         <Component {...props} {...rest} />
//       ) : (
//         <Redirect
//           to={{
//             pathname: '/',
//             state: { from: props.location },
//           }}
//         />
//       )
//     }
//   />
// );

ReactGA.initialize(GA_ID);
var lang = 'en';
export default class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang : localStorage.getItem('i18nextLng')
    };
  }
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  componentDidUpdate(prevProps, prevState) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }  
  render() {
    if (this.state.lang == null) {
      localStorage.setItem('i18nextLng', 'en');
    }
    else{
      lang = localStorage.getItem('i18nextLng')
    }
    return (
      <div>
        <Router>
          {/* <TopNav /> */}
          <Switch>

            {/* Checkout Card */}
            <PrivateRoute path={'/checkout/:id'} component={CheckoutStripe} />

            {/* Premium Pages */}
            <Route path={'/' + lang + '/premium/profile'} component={Profile} />
            <Route path={'/' + lang + '/premium/organization'} component={Organization} />
            <Route path={'/' + lang + '/premium/send-receive'} component={SendReceive} />
            <Route path={'/' + lang + '/premium/earnings'} component={Earnings} />
            <Route path={'/' + lang + '/premium/tools'} component={Tools} />
            {/* END PREMIUM PAGES */}

            <Route exact path={`/auth/password-reset/:email?/:token?`} component={PasswordReset} />

            {/* Maintenance Page */}
            <Route exact path={'/' + lang + '/business-in-a-box'} component={BusinessInABox} />
            <Route exact path={'/' + lang + '/business-in-a-box-merchant-invitation'} component={BusinessInABoxMerchantInvitation} />
            <Route exact path={'/' + lang + '/congratulations'} component={Congratulations} />
            <Route exact path={'/' + lang + '/maintenance'} component={Maintenance} />
            <Route exact path={'/' + lang + '/dao'} component={Dao} />
            <Route exact path={'/' + lang + '/dispensary'} component={Dispensary} />
            <Route exact path={'/' + lang + '/escorts'} component={Escorts} />
            <Route exact path={'/' + lang + '/unbanked'} component={Unbanked} />

            <Route exact path={'/' + lang} component={Home} />
            <Route exact path={'/' + lang + '/landing'} component={LandingNew}/>
            <Route exact path={'/' + lang + '/contact'} component={Contact}/>
            <Route exact path={'/' + lang + '/support'} component={Support}/>
            <Route exact path={'/' + lang + '/wallet'} component={Wallet}/>
            <Route exact path={'/' + lang + '/merchant'} component={BecomeMerchant}/>
            <Route exact path={'/' + lang + '/stacking'} component={Stacking}/>
            <Route exact path={'/' + lang + '/stackcoins'} component={StackCoins}/>
            <Route exact path={'/' + lang + '/wordbackup'} component={WordBackup}/>
            <Route exact path={'/' + lang + '/privacy-policy'} component={Privacy}/>
            <Route exact path={'/' + lang + '/brand-assets'} component={BrandAssets} />
            <Route exact path={'/' + lang + '/terms-and-condition'} component={Terms}/>
            <Route exact path={'/' + lang + '/exchange'} component={Exchange}/>
            <Route exact path={'/' + lang + '/media'} component={Media}/>
            <Route exact path={'/' + lang + '/press'} component={Press}/>
            <Route exact path={'/' + lang + '/whitepaper'} component={WhitePaper}/>
            <Route exact path={'/' + lang + '/yellowpaper'} component={YellowPaper}/>
            <Route exact path={'/' + lang + '/about'} component={About} />
            <Route exact path={'/' + lang + '/become-an-affiliate'} component={BecomeAnAffiliate} />
            <Route exact path={'/' + lang + '/massadoption'} component={MassAdoption} />
            <Route exact path={'/' + lang + '/meet-ups'} component={MeetUps} />
            <Route exact path={'/' + lang + '/year-end-report'} component={YearEndReport} />
            <Route exact path={'/' + lang + '/dear-stakeholders'} component={DearStakeholders} />
            <Route exact path={'/' + lang + '/letter-to-stakeholders'} component={LettersToStakeholders} />
            <Route exact path={'/' + lang + '/consumer-wallet-app'} component={ConsumerWalletApp} />
            <Route exact path={'/' + lang + '/merchant-wallet-app'} component={MerchantWalletApp} />
            <Route exact path={'/' + lang + '/mindmap/connect'} component={Mindmap} />
            <Route exact path={'/' + lang + '/mindmap'} component={Mindmap} />
            <Route exact path={'/' + lang + '/bug-bounty'} component={BugBounty} />
            <Route exact path={'/' + lang + '/top-100-holders'} component={Top100Holders} />
            <Route exact path={'/' + lang + '/nft-collections'} component={NFTCollections} />
            <Route exact path={'/' + lang + '/faqs'} component={Faqs} />
            <Route exact path={'/' + lang + '/mining'} component={Mining} />
            <Route exact path={'/' + lang + '/founders-club-top-50-nft'} component={FoundersClubTop50NFT} />
            <Route exact path={'/' + lang + '/founders-club'} component={FoundersClub} />
            <Route exact path={'/' + lang + '/nfts/:id'} component={NFTsSingle} />
            <Route exact path={'/' + lang + '/nfts'} component={NFTs} />
            <Route exact path={'/' + lang + '/collect-your-loyal-nft'} component={CollectYourLoyalNFT} />
            {/* Newsletters */}
            <Route exact path={'/' + lang + '/newsletter/ovato-nation'} component={OvatoNation} />
            <Route exact path={'/' + lang + '/newsletter/ovato-nation-v1-issue-2'} component={OvatoNation2} />
            <Route exact path={'/' + lang + '/newsletter/ovato-nation-v1-issue-3'} component={OvatoNation3} />
            <Route exact path={'/' + lang + '/newsletter/hard-fork-v2-issue-1'} component={HardFork} />
            <Route exact path={'/' + lang + '/announcement'} component={Announcement} />
            <Route exact path={'/' + lang + '/financial-overview-and-roadmap-2024'} component={FinancialOverview} />
            

            <Route exact path={'/' + lang + '/leaderboard'} component={Leaderboard} />
            <Route exact path={'/' + lang + '/discover'} component={Discover} />

            {/* Test Data */}
            <Route exact path={'/' + lang + '/test-data'} component={TestData} />
            <Route exact path={'/' + lang + '/test-data-2'} component={TestData2} />
            {/* PLEDGE  */}
            {/* <Route exact path={'/' + lang + '/pledge-drive'} component={PledgeDrive} /> */}
            {/* <Route exact path={'/' + lang + '/landing-new'} component={LandingNew} /> */}
            
            {/* PREMIUM */}
            <Redirect from='/premium/profile' to={'/' + lang + '/premium/profile'} />
            <Redirect from='/premium/organization' to={'/' + lang + '/premium/organization'} />
            <Redirect from='/premium/send-receive' to={'/' + lang + '/premium/send-receive'} />
            <Redirect from='/premium/earnings' to={'/' + lang + '/premium/earnings'} />
            <Redirect from='/premium/tools' to={'/' + lang + '/premium/tools'} />

            <Redirect from='/maintenance' to={'/' + lang + '/maintenance'} />
            <Redirect from='/business-in-a-box' to={'/' + lang + '/business-in-a-box'} />
            <Redirect from='/business-in-a-box-merchant-invitation' to={'/' + lang + '/business-in-a-box-merchant-invitation'} />
            <Redirect from='/congratulations' to={'/' + lang + '/congratulations'} />
            <Redirect from='/dao' to={'/' + lang + '/dao'} />
            <Redirect from='/dispensary' to={'/' + lang + '/dispensary'} />
            <Redirect from='/escorts' to={'/' + lang + '/escorts'} />
            <Redirect from='/unbanked' to={'/' + lang + '/unbanked'} />
            <Redirect from='/landing' to={'/' + lang + '/landing'} />
            <Redirect from='/contact' to={'/' + lang + '/contact'} />
            <Redirect from='/support' to={'/' + lang + '/support'} />
            <Redirect from='/wallet' to={'/' + lang + '/wallet'} />
            <Redirect from='/merchant' to={'/' + lang + '/merchant'} />
            <Redirect from='/stacking' to={'/' + lang + '/stacking'} />
            <Redirect from='/stackcoins' to={'/' + lang + '/stackcoins'} />
            <Redirect from='/wordbackup' to={'/' + lang + '/wordbackup'} />
            <Redirect from='/privacy-policy' to={'/' + lang + '/privacy-policy'}/>
            <Redirect from='/brand-assets' to={'/' + lang + '/brand-assets'} />
            <Redirect from='/terms-and-condition' to={'/' + lang + '/terms-and-condition'}/>
            <Redirect from='/exchange' to={'/' + lang + '/exchange'} />
            <Redirect from='/media' to={'/' + lang + '/media'} />
            <Redirect from='/press' to={'/' + lang + '/press'} />
            <Redirect from='/whitepaper' to={'/' + lang + '/whitepaper'} />
            <Redirect from='/yellowpaper' to={'/' + lang + '/yellowpaper'} />
            <Redirect from='/about' to={'/' + lang + '/about'} />
            <Redirect from='/become-an-affiliate' to={'/' + lang + '/become-an-affiliate'} />
            <Redirect from='/meet-ups' to={'/' + lang + '/meet-ups'} />
            <Redirect from='/year-end-report' to={'/' + lang + '/year-end-report'} />
            <Redirect from='/massadoption' to={'/' + lang + '/massadoption'} />
            <Redirect from='/dear-stakeholders' to={'/' + lang + '/dear-stakeholders'} />
            <Redirect from='/letter-to-stakeholders' to={'/' + lang + '/letter-to-stakeholders'} />
            <Redirect from='/consumer-wallet-app' to={'/' + lang + '/consumer-wallet-app'} />
            <Redirect from='/merchant-wallet-app' to={'/' + lang + '/merchant-wallet-app'} />
            <Redirect from='/mindmap/connect' to={'/' + lang + '/mindmap/connect'} />
            <Redirect from='/mindmap' to={'/' + lang + '/mindmap'} />
            
            
            <Redirect from='/bug-bounty' to={'/' + lang + '/bug-bounty'} />
            <Redirect from='/top-100-holders' to={'/' + lang + '/top-100-holders'} />
            <Redirect from='/nft-collections' to={'/' + lang + '/nft-collections'} />
            <Redirect from='/faqs' to={'/' + lang + '/faqs'} />
            <Redirect from='/mining' to={'/' + lang + '/mining'} />
            <Redirect from='/founders-club-top-50-nft' to={'/' + lang + '/founders-club-top-50-nft'} />
            <Redirect from='/founders-club' to={'/' + lang + '/founders-club'} />
            <Redirect from='/nfts/:id' to={'/' + lang + '/nfts/:id'} />
            <Redirect from='/nfts' to={'/' + lang + '/nfts'} />
            <Redirect from='/collect-your-loyal-nft' to={'/' + lang + '/collect-your-loyal-nft'} />
            {/* <Redirect from='/landing-new' to={'/' + lang + '/landing-new'} /> */}
             <Redirect from='/newsletter/ovato-nation' to={'/' + lang + '/newsletter/ovato-nation'} />
             <Redirect from='/newsletter/ovato-nation-v1-issue-2' to={'/' + lang + '/newsletter/ovato-nation-v1-issue-2'} />
             <Redirect from='/newsletter/ovato-nation-v1-issue-3' to={'/' + lang + '/newsletter/ovato-nation-v1-issue-3'} />
             <Redirect from='/newsletter/hard-fork-v2-issue-1' to={'/' + lang + '/newsletter/hard-fork-v2-issue-1'} />
             <Redirect from='/pledge-drive' to={'/' + lang + '/pledge-drive'} />
             <Redirect from='/announcement' to={'/' + lang + '/announcement'} />
             <Redirect from='/financial-overview-and-roadmap-2024' to={'/' + lang + '/financial-overview-and-roadmap-2024'} />
             <Redirect from='/leaderboard' to={'/' + lang + '/leaderboard'} />
             <Redirect from='/discover' to={'/' + lang + '/discover'} />
            <Redirect from='/' to={'/' + lang} />
            <Route path='' component={NotFound} />
          </Switch>
          {/* <BottomNav /> */}
        </Router>
      </div>
    );
  }
}
