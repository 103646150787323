import React, { useEffect } from "react";

import { Fancybox } from "@fancyapps/ui";

import ImgPlay from '../../assets/img/discover2/img-right-arrow.png'
import ImgWatch from '../../assets/img/discover2/img-watch-video-text.png'

const DiscoverVideo2 = ({ videoURL, videoThumb = '', options = {} }) => {
  // Initialize Fancybox for videos
  useEffect(() => {
    Fancybox.bind("[data-fancybox='video']", options);
    return () => {
      Fancybox.destroy(); // Clean up when the component unmounts
    };
  }, [options]);

  return (
    <div className='v2-mt-1 v2-discover-play-btn-wrap'>
        <a href={videoURL} className='v2-discover-play-btn' data-fancybox="video">
            <div className='v2-discover-glow'></div>
            <div className='v2-discover-play-img'>
                <img src={ImgPlay} />
            </div>
            <div className='v2-discover-watch-video-text'>
                <img src={ImgWatch} />
            </div>
        </a>
    </div>
  );
};

export default DiscoverVideo2;