import React, { Component } from 'react'
import MindmapTopNav from '../containers/NewTopNav3';
import MindmapBottomNav from '../containers/NewBottomNav3';
import MindmapBody2 from './MindmapBody2';

// CSS
import '../assets/css/MindmapBody2.css'

export default class Mindmap2 extends Component {
  render() {
    return (
      <div className='mindmap-main v2-mindmap-main'>
        <MindmapTopNav />
        <MindmapBody2 />
        <MindmapBottomNav />
      </div>
    )
  }
}