import React, { Component } from 'react';

// CSS
import '../assets/css/Global.css'
import '../assets/css/Discover.css'
import '../assets/css/Discover2.css'
import '../assets/css/Discover3.css'

// Components
import Header from '../components/header/Header2';
import Section1 from '../components/discover3/Section1';
import Footer from '../components/footer/Footer2';

class Discover extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='discover-main v2-discover-main v3-discover-main'>
        {/* Header */}
        <Header />

        {/* SECTION 1 */}
        <div style={{ zIndex: 1000 }}>
          <Section1 />
        </div>

        {/* FOoter */}
        <Footer />
      </div>
    );
  }
}

export default Discover;