import React from 'react'

import '../../assets/css/Footer2.css'

const Footer2 = () => {

    const currentYear = new Date().getFullYear();

  return (
    <footer class="footer">
        <div class="footer-lower">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <ul class="footer-copyright">
                            <li><a href="/privacy-policy">Privacy Policy</a></li>
                            <li><a href="/terms-and-condition">Terms and Condition</a></li>
                            <li><a>Copyright © 2018 - {currentYear} Ovato</a></li>
                            <li>All Rights Reserved</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer2