import React from 'react'

import '../../assets/css/Header2.css'

const Header2 = () => {
  return (
    <header class="site-header new-site-header">
        <div class="site-header-upper-new">
            <div class="container">
                <p class="poppins p-1 font-22 v2-tl-font-17 v2-m-font-12 ls-3 bold white-text text-center text-uppercase m-0 v2-m-ls-1">Easiest Way To <strong class="orange-text">Make Big Money</strong> in Crypto !</p>
            </div>
        </div>
    </header>
  )
}

export default Header2