import React, { Component } from 'react';

// Images
import ImgOvatoWallet from '../../assets/img/discover/img-ovato-wallet.png'
import ImgGovato from '../../assets/img/discover/img-govato.png'
import ImgGoogleMeet from '../../assets/img/discover-3/img-google-meet.png'
import ImgSmall from '../../assets/img/discover-3/img-small.png'
import ImgMedium from '../../assets/img/discover-3/img-medium.png'
import ImgLarge from '../../assets/img/discover-3/img-large.png'
import ImgBonus from '../../assets/img/discover-3/img-bonus.png'

import ImgSmallMobile from '../../assets/img/discover-3/img-small-mobile.png'
import ImgMediumMobile from '../../assets/img/discover-3/img-medium-mobile.png'
import ImgLargeMobile from '../../assets/img/discover-3/img-large-mobile.png'

import DiscoverVideo2 from '../video/DiscoverVideo2';

import DiscoverVideo3 from '../video/DiscoverVideo3';
import RadioTime from './RadioTime';
import Timer from './Timer';
import PhoneInput from 'react-phone-input-2';
import { Service } from '../../providers/Services';
import { toast } from 'react-toastify';
import LoadingBG from '../loader/LoadingBG';
class Section1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: null,
            userNumber: '',
            phone: null,
            country_code: '',
            loading: false
        }
    }

    handleTime = (val) => {
        this.setState({ time: val })
    }

    handleNumber = (e) => {
        this.setState({ userNumber: e.target.value })
    }

    handleSubmit = (e) => {
        e.preventDefault();
    }

    onPhoneChange = (value, country) => {
        const country_code = "+" + country.dialCode;
        const phone = value.toString().replace(country.dialCode, "")
        this.setState({ phone: country_code + "-" + phone, country_code: country })
    }

    handleConfirmMObile = async () => {

        if (this.state.country_code === ""
            || this.state.phone === null
            || this.state.phone.split('-')[1] === "") {
            toast.error('Please give country code and phone!')
            return
        }

        if (!this.state.time) {
            toast.error('Please provide time!')
            return
        }

        this.setState({ loading: true })
        const payload = {
            phone: this.state.phone.split('-')[1],
            country_code: "+" + this.state.country_code.dialCode,
            discovery_time: this.state.time,
            type: 2
        }

        try {
            await Service.discvery(payload)
            toast.success('We will send you an invite soon, thank you!')
            this.setState({
                time: null,
            })
            this.setState({ loading: false })
        }
        catch (err) {
            toast.error(err.data.message + '!')
            this.setState({ loading: false })

        }

    }

    render() {
        return (
            <>
                <section class="home-1 new-home-2 new-home-2-new discover-1 v2-discover-1 v3-discover-1">
                    <div class="container">

                        <div class="row flex align-center home-1-row-2">
                            <div class="col-sm-7 home-1-right-col home-1-right-col-new home-1-right-col-new-2">

                                <div className='v2-mb-0 img-wrap discover-logo-img-wrap'>
                                    <a href='/'><img src={ImgGovato} width={95} /></a>
                                </div>

                                <h1 class="poppins text-center v2-font-39 v2-tp-font-34 v2-m-font-22 bold white-text v2-m-mb-0 v2-mt-0"><strong class="double-underline double-underline-heading bold">Discover</strong></h1>

                                <ul class="ul-checklist white-text discover-checklist">
                                    <li>Great compensation package!</li>
                                    <li>Tools and support! </li>
                                    <li>Assets that are appreciable! </li>
                                    <li>Royalties forever! </li>
                                    <li>Multiple streams of income!</li>
                                </ul>

                                <div className='text-center img-wrap'>
                                    <DiscoverVideo2 videoURL={'https://www.youtube.com/watch?v=cekUUvu8JIM'} />
                                </div>

                            </div>

                            <div class="col-sm-5 relative">
                                <div class="img-wrap new-banner-img">
                                    <a href={'#'}><img src={ImgOvatoWallet} /></a>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <!-- end class container --> */}
                </section>

                <section className='discover-2 v2-discover-2 v3-discover-2 v2-tp-pt-2'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col col-sm-6 text-center'>

                                <div className='v3-discover-box v3-discover-orange-box'>

                                    <h2 className='poppins v2-font-24 semi-bold white-text text-center v2-pt-1-5'>Join Now</h2>

                                    {/* <div className='v3-discover-timer'>00:30:01 Left</div> */}

                                    <div className='v3-discover-timer'><Timer initialHours={0} initialMinutes={30} initialSeconds={0} /> <span>Left</span></div>

                                    <div className='v3-discover-nfts-wrap'>
                                        <div className='img-wrap v2-mt-1'>
                                            <img src={ImgSmall} className='desktop' />
                                            <img src={ImgSmallMobile} className='mobile' />
                                        </div>

                                        <div className='img-wrap v2-mt-1'>
                                            <img src={ImgMedium} className='desktop' />
                                            <img src={ImgMediumMobile} className='mobile' />
                                        </div>

                                        <div className='img-wrap v2-mt-1'>
                                            <img src={ImgLarge} className='desktop' />
                                            <img src={ImgLargeMobile} className='mobile' />
                                        </div>
                                    </div>

                                    <div class="btn-wrap home-3-btn-wrap v3-discover-btn">
                                        <a href='https://ovato.com/en/nfts' target='_blank' class="site-btn site-btn-2 site-btn-subtext signup-v3-btn">
                                            Join Now
                                        </a>
                                    </div>

                                    <div className='v3-discover-bonus'>
                                        <img src={ImgBonus} />
                                    </div>
                                </div>

                            </div>

                            <div className='col col-sm-6 text-center'>

                                <div className='v3-discover-box v3-discover-blue-box'>
                                    <h2 className='poppins v2-font-24 semi-bold blue-text v2-mb-0'><span>Have questions?</span> <br className='mobile' /><span className='blue-text-3 v2-m-font-20'>No problem</span></h2>

                                    <p className='poppins v2-font-18 semi-bold blue-text text-center v2-mb-0 v2-mt-0-5 v2-m-mt-1-5'>Come to a Google meet</p>

                                    <div className='v2-mt-1-5 v2-m-mt-1 row d-flex align-items-center'>
                                        <div className='col'>
                                            <ul className='v3-discover-ul'>
                                                <li>Ask whatever you like</li>
                                                <li>Get answers from multiple sources on how to earn more</li>
                                                <li>Learn from others</li>
                                            </ul>
                                        </div>

                                        <div className='col v2-m-mt-1'>
                                            <img src={ImgGoogleMeet} />
                                        </div>
                                    </div>

                                    <div className='v2-mt-2'>
                                        <RadioTime handleChange={this.handleTime} selected={this.state.time} value={0} text={"Monday 2PM EST"} />
                                    </div>

                                    <div className='v2-mt-1'>
                                        <RadioTime handleChange={this.handleTime} selected={this.state.time} value={1} text={'Tuesday 2PM EST'} />
                                    </div>

                                    <div className='v2-mt-1'>
                                        <RadioTime handleChange={this.handleTime} selected={this.state.time} value={2} text={'Wednesday 2PM EST'} />
                                    </div>

                                    <div className='v2-mt-1'>
                                        <RadioTime handleChange={this.handleTime} selected={this.state.time} value={3} text={'Thursday 2PM EST'} />
                                    </div>

                                    <div className='v2-mt-1'>
                                        <RadioTime handleChange={this.handleTime} selected={this.state.time} value={4} text={'Friday 2PM EST'} />
                                    </div>

                                    <div className='v2-mt-1'>
                                        <RadioTime handleChange={this.handleTime} selected={this.state.time} value={5} text={'Saturday 11AM EST'} />
                                    </div>

                                    <div className='v2-mt-2 v3-discover-invite-box'>
                                        <h2 className='poppins v2-font-17 semi-bold blue-text text-center v2-mb-0-75'>Send invite link to:</h2>
                                        <form className='v3-discover-invite-form' onSubmit={this.handleSubmit}>
                                            <div className='form-row phone-row'>
                                                <PhoneInput
                                                    country={'us'}
                                                    value={this.state.phone}
                                                    onChange={this.onPhoneChange}
                                                    enableSearch={true}
                                                    placeholder={'Enter mobile no.'}
                                                    dropdownStyle={{
                                                        textAlign: "left",
                                                        paddingLeft: "10px",
                                                        height: '500px'
                                                    }}
                                                    inputStyle={{
                                                        textAlign: "left",
                                                        backgroundColor: '#fff'
                                                    }}
                                                    searchStyle={{
                                                        textAlign: 'left'
                                                    }}

                                                />
                                            </div>
                                            <div>
                                                <button onClick={this.handleConfirmMObile}>
                                                    Confirm Mobile Number
                                                </button>
                                            </div>
                                            {this.state.loading ? <LoadingBG show={true} /> : null}
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <DiscoverVideo3 />
            </>
        );
    }
}

export default Section1;